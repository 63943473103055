import React, { FC } from 'react'

import { BlogPost } from '../utils/types'

import Container from './Container'
import BlogEntryTeaser from './BlogEntryTeaser'

interface Props {
  posts: {
    node: BlogPost
  }[]
}

const BlogEntryList: FC<Props> = ({ posts }) => (
  <Container>
    <div className="grid gap-y-10 gap-x-4 sm:grid-cols-2 lg:grid-cols-3">
      {posts.map(({ node }, k) => (
        <BlogEntryTeaser key={`entry-${k}`} entry={node} />
      ))}
    </div>
  </Container>
)

export default BlogEntryList
