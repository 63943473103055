// Gatsby supports TypeScript natively!
import React, { ReactElement } from 'react'
import { PageProps, graphql, Link } from 'gatsby'

import { BlogPost, SiteMetaData } from '../utils/types'

import Next from '../icons/next.svg'

import Layout from '../components/Layout'
import Container from '../components/Container'
import SEO from '../components/SEO'

import HeroBanner from '../components/HeroBanner'
import TriangleSeparator from '../components/TriangleSeparator'
import Grid from '../components/Grid'
import BlogEntryList from '../components/BlogEntryList'

type Data = {
  site: SiteMetaData
  location: Location
  allMarkdownRemark: {
    edges: {
      node: BlogPost
    }[]
  }
}

const IndexPage = ({ data }: PageProps<Data>): ReactElement => {
  const { introText } = data.site.siteMetadata

  return (
    <Layout>
      <SEO title="Ellie Strejlau" />

      <HeroBanner>
        <p className="text-2xl md:text-4xl">{introText}</p>
      </HeroBanner>

      <Container className="mt-16">
        <h2 className="header3">Latest Entries</h2>
        <BlogEntryList posts={data.allMarkdownRemark.edges} />
        <Link to="/blog" className="mt-4 flex justify-end h-full items-center">
          <span className="text-sm text-right">Read More Entries</span>
          <div className="h-7 w-5 ml-2 flex-shrink-0">
            <Next />
          </div>
        </Link>
      </Container>

      <TriangleSeparator skew="right" className="mt-10" />

      <Container>
        <h2 className="header3">Specialties</h2>
        <Grid gap={0}>
          <div className="lg:col-span-2 sm:pr-8">
            <h3 className="header5 mt-0">Software</h3>
            <ul className="fancy lg:col-count-2">
              <li key="specialties-software-0">Web fundamentals</li>
              <li key="specialties-software-1">
                Software &amp; Data Architecture
              </li>
              <li key="specialties-software-2">
                UI Component Libraries built with React or Web Components
              </li>
              <li key="specialties-software-3">Bundling and API Management</li>
              <li key="specialties-software-4">
                Version Control Systems &amp; Semantic Versioning
              </li>
              <li key="specialties-software-5">Continuous Integration</li>
              <li key="specialties-software-6">Package Publication</li>
              <li key="specialties-software-7">Automated Testing</li>
            </ul>
          </div>
          <div className="sm:border-l sm:pl-8">
            <h3 className="header5 mt-0">Peopleware</h3>
            <ul className="fancy">
              <li key="specialties-peopleware-0">Team Leadership</li>
              <li key="specialties-peopleware-1">Staff Management</li>
              <li key="specialties-peopleware-2">Guild/Club Management</li>
              <li key="specialties-peopleware-3">Public Speaking</li>
              <li key="specialties-peopleware-4">Teaching/Mentoring</li>
            </ul>
          </div>
        </Grid>
      </Container>

      <TriangleSeparator className="mt-10" />

      <Container>
        <Grid numCols={2} gap={0}>
          <div className="sm:pr-8">
            <h2 className="header3 mt-0 md:mt-8">Relevant Experience</h2>
            <h3 className="header5">Goldbelly</h3>
            <p>
              <strong className="mr-2">2021 &ndash; Present</strong>
              <br />
              Senior Frontend Engineer
            </p>
            <h3 className="header5">Phase2 Technology</h3>
            <p>
              <strong className="mr-2">2016 &ndash; 2021</strong>
              <br />
              Software Architect
              <br />
              Senior Developer
            </p>
            <h3 className="header5">NorthPoint Digital / EY</h3>
            <p>
              <strong className="mr-2">2011 &ndash; 2016</strong>
              <br />
              Technical Project Manager
              <br />
              Senior Developer
              <br />
              Developer
            </p>
          </div>
          <div className="mt-10 sm:border-l sm:pl-8 sm:mt-0">
            <h2 className="header3 mt-0 md:mt-8">Education</h2>
            <h3 className="header5">Pace University</h3>
            <p>
              <strong className="mr-2">2014 &ndash; 2017</strong>
              <br />
              Master of Science, Information Systems
            </p>
            <h3 className="header5">Farmingdale State College</h3>
            <p>
              <strong className="mr-2">2006 &ndash; 2011</strong>
              <br />
              Bachelor of Science, Computer Programming and Information Systems
            </p>
          </div>
        </Grid>
      </Container>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        introText
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            isoDate: date
            title
            description
            cover {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
      }
    }
  }
`
