import React, { FC } from 'react'
import classNames from 'classnames'

interface Props {
  mono?: boolean
  skew?: 'left' | 'right'
  className?: string | string[]
}

const TriangleSeparator: FC<Props> = ({
  mono = false,
  className = '',
  skew = 'left',
}) => {
  return (
    <hr
      className={classNames(
        {
          'bg-mono-triangles': mono,
          'bg-color-triangles': !mono,
          '-skew-y-3': skew === 'left',
          'skew-y-3': skew === 'right',
        },
        '-mx-8 mb-10 transform px-8 py-4 border-0 shadow-inner',
        className
      )}
    />
  )
}

export default TriangleSeparator
