import React, { FC, ReactElement } from 'react'
import classNames from 'classnames'

interface Props {
  className?: string | string[]
}

const Container: FC<Props> = ({ children, className }): ReactElement => (
  <section className={classNames('container mx-auto', className)}>
    {children}
  </section>
)

export default Container
