import React, { FC } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { BlogPost } from '../utils/types'

interface EntryPreview {
  entry: BlogPost
}

const BlogEntryTeaser: FC<EntryPreview> = ({ entry }) => {
  const { title, date, isoDate, cover, description } = entry.frontmatter
  const { slug } = entry.fields

  return (
    <article className="bg-mono-switch3 shadow-outer relative">
      <Link
        aria-label={title}
        to={slug}
        className="absolute inset-0 z-10 border-none"
      />
      {(cover && (
        <Img
          className="clipped-image"
          fluid={{
            ...cover.childImageSharp.fluid,
            aspectRatio: 3 / 2,
          }}
        />
      )) || <img src="/socialBgSmall.png" />}

      <div className="px-6 pb-8">
        <time dateTime={isoDate} className="text-sm font-bold">
          {date}
        </time>
        <div className="header4 mt-4 mb-2">{title}</div>
        <p>{description}</p>
      </div>
    </article>
  )
}

export default BlogEntryTeaser
