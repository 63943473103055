import React, { FC, ReactElement } from 'react'
import classNames from 'classnames'

interface Props {
  numCols?: 1 | 2 | 3 | 4 | 5 | 6
  gap?: number
}

const Grid: FC<Props> = ({ children, numCols = 3, gap = 4 }): ReactElement => (
  <div
    className={classNames(
      {
        [`gap-${gap}`]: !!gap,
        [`sm:grid-cols-2`]: numCols >= 2,
        [`lg:grid-cols-${numCols}`]: numCols >= 3,
      },
      'grid grid-flow-row'
    )}
  >
    {children}
  </div>
)

export default Grid
