import React, { FC } from 'react'
import classNames from 'classnames'

interface Props {
  mono?: boolean
}

const HeroBanner: FC<Props> = ({ mono = false, children }) => {
  return (
    <section
      className={classNames(
        {
          'bg-mono-triangles': mono,
          'bg-color-triangles': !mono,
        },
        '-mx-8 mb-16 transform -skew-y-3 px-8 shadow-inner'
      )}
    >
      <div className="container mx-auto transform skew-y-3 py-16 font-serif text-center">
        {children}
      </div>
    </section>
  )
}

export default HeroBanner
